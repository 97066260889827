import replaceOnce from "replace-once";
import _ from "lodash";
import renderDocumentVariables from "../../../mixins/documents/renderDocumentVariables";
import banks from "../../../json/companyBanks.json";
import formatThings from "@/mixins/general/formatThings";
import policyMixins from "@/mixins/policies/policyMixins";

import {
  jsPDF
} from "jspdf";
const doc = new jsPDF();
import {
  format
} from "date-fns";

export default {
  mixins: [renderDocumentVariables, formatThings, policyMixins],
  data() {
    return {
      contractPages: [],
      contrato: {
        cliente: {
          endereco: {},
        },
        empresa: "",
        pagamento: {
          credito: {
            ativo: false,
            valor: "",
            cartao: [],
          },
          transferencia: {
            ativo: false,
            valor: "",
            banco: "",
          },
          personalizado: {
            ativo: false,
            valor: "",
            nome: "",
            obs: "",
          },
          boleto: {
            ativo: false,
            valor: "",
            obs: "",
          },
          carta: {
            ativo: false,
            valor: "",
            id_voyage: "",
            lista: []
          },
          terceiros: {
            ativo: false,
          },
          total: 0
        },
        banks,
        oldBanks: [{
            name: "Banco Bradesco",
            number: 237,
            agency: "1246",
            account: "15600-0",
          },
          {
            name: "Banco Safra S.A",
            number: 422,
            agency: "0023",
            account: "395014-1",
          },
          {
            name: "Banco Cooperativo do Brasil",
            number: 756,
            agency: "3166",
            account: "50340-1",
          },
        ],
        viajantes: [],
        listaHoteis: [],
        aereo: {
          voos: []
        },
        listaServicos: [],
        politicas: [],
        produtos: [],
        documentos: [],
      },
    }
  },
  beforeMount() {
    this.policy.filters.module = "contract";
    this.policy.filters.id = this.$route.params.id;
    this.policy.filters.showPolicy = 1;
    this.getPolicies();
  },
  methods: {

    getContract() {
      this.$http
        .post("/contract/details", {
          id: this.contractId,
        })
        .then(({
          data
        }) => {

          // O CONTRATO FRIENDLY VERSION
          console.log("ESTRUTURA CONTRATO", data.meta.contract_documents);

          this.contract = data;
          this.contrato.id = parseInt(data.id);

          this.getDocuments();

          let name = ``;

          if (data.customer == false) {
            // if (contract.customer) {
            //     name = `${contract.customer.first_name.toUpperCase()} ${contract.customer.last_name.toUpperCase()}`;
            // } else {
            //     name = `${contract.meta.client_name.toUpperCase()}`;
            // }
            if (data.meta.client_name != undefined) {
              name = `${data.meta.client_name.toUpperCase()}`;
            }
          } else {
            if (data.customer.person_type == "Pessoa Física") {
              name = `${data.customer.first_name.toUpperCase()} ${data.customer.last_name.toUpperCase()}`;
            } else {
              name = `${data.customer.trading_name.toUpperCase()} `;
            }
          }

          document.title = `${
                        this.contract.id
                    } - ${name} - HAYA`;

          // document.title = `${
          //     this.contract.id
          // } - ${this.contract.meta.client_name.toUpperCase()} - HAYA`;

          if (
            data.user.id != this.$store.state.userData.id &&
            !this.$root.isAdmin()
          ) {
            //  this.$router.push(`/contracts/list`);

            this.$tabs.close(`/contracts/view/${data.id}`, `/contracts/list`);
          }

          console.log("ESTRUTURA CONTRATO PARTE 0");

          // CLIENTE
          this.contrato.documentos = data.meta.contract_documents ?
            data.meta.contract_documents : [{
              id: 0,
              name: "Padrão"
            }];
          this.contrato.empresa = data.company;

          if (data.customer.id == undefined) {

            this.contrato.cliente.pessoa = data.person_type;
            this.contrato.cliente.nome = data.meta.client_name.toUpperCase();

            if (data.person_type == "Pessoa Jurídica") {
              this.contrato.cliente.cnpj = data.meta.client_CNPJ ?
                data.meta.client_CNPJ :
                "";
            }

            if (data.person_type == "Pessoa Física") {
              this.contrato.cliente.nascimento =
                this.formatMultiDates(data.meta.client_birthday);
              this.contrato.cliente.cpf = data.meta.client_CPF ?
                data.meta.client_CPF :
                "";
              this.contrato.cliente.rg = data.meta.client_RG ?
                data.meta.client_RG :
                "";
              this.contrato.cliente.orgao = data.meta
                .client_doc_emission ?
                data.meta.client_doc_emission.toUpperCase() +
                "-" +
                data.meta.client_doc_emission_state :
                "";
            }

            this.contrato.cliente.telefone = data.meta.client_phone;
            this.contrato.cliente.celular =
              data.meta.client_mobile_phone;
            this.contrato.cliente.email = data.meta.client_email.toUpperCase();
            this.contrato.cliente.endereco.rua = data.meta.client_location_street.toUpperCase();
            this.contrato.cliente.endereco.numero =
              data.meta.client_location_number;
            this.contrato.cliente.endereco.bairro = data.meta.client_location_neighborhood.toUpperCase();
            this.contrato.cliente.endereco.complemento = data.meta
              .client_location_complement ?
              data.meta.client_location_complement.toUpperCase() :
              "";
            this.contrato.cliente.endereco.estado = data.meta.client_location_state.toUpperCase();
            this.contrato.cliente.endereco.cidade = data.meta.client_location_city.toUpperCase();
            this.contrato.cliente.endereco.cep = data.meta.client_CEP;

          } else {
            this.contrato.cliente.pessoa = data.customer.person_type;
            this.contrato.cliente.cnpj = data.customer.cnpj;
            this.contrato.cliente.empresa = data.customer.company_name;
            this.contrato.cliente.nome = `${data.customer.first_name.toUpperCase()} ${data.customer.last_name.toUpperCase()}`;
            this.contrato.cliente.telefone = data.customer.phone;
            this.contrato.cliente.celular =
              data.customer.mobile_phone;
            this.contrato.cliente.email = data.customer.email.toUpperCase();
            this.contrato.cliente.endereco.rua = data.customer.location_street.toUpperCase();
            this.contrato.cliente.endereco.numero =
              data.customer.location_number;
            this.contrato.cliente.endereco.bairro = data.customer.location_neighborhood.toUpperCase();
            this.contrato.cliente.endereco.complemento = data.customer.location_complement ?
              data.customer.location_complement.toUpperCase() :
              "";
            this.contrato.cliente.endereco.estado = data.customer.location_state.toUpperCase();
            this.contrato.cliente.endereco.cidade = data.customer.location_city.toUpperCase();
            this.contrato.cliente.endereco.cep = data.customer.cep;

            this.contrato.cliente.nascimento =
              this.formatMultiDates(data.customer.birthday);

            this.contrato.cliente.cpf = data.customer.cpf ?
              data.customer.cpf :
              "";

            this.contrato.cliente.rg = data.customer.rg ?
              data.customer.rg :
              "";
            this.contrato.cliente.orgao =
              data.customer.rg_emissor ?
              data.customer.rg_emissor.toUpperCase() +
              "-" +
              data.customer.rg_state :
              "";
          }

          console.log("ESTRUTURA CONTRATO PARTE 1");


          console.log("ESTRUTURA CONTRATO - PARTE 2");

          let findMonth = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          let replaceMonth = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ];

          this.contrato.cliente.data = replaceOnce(
            format(new Date(), "dd, MMMM, yyyy"),
            findMonth,
            replaceMonth,
            "gi"
          );

          let productNames = "";
          let contracted_services = JSON.parse(
            data.meta.contracted_services
          );

          contracted_services.forEach((produto) => {
            if (produto == "hotel") {
              productNames += "Hotel, ";
            }

            if (produto == "flight") {
              productNames += "Passagem Aérea, ";
            }

            if (produto == "service") {
              productNames += "Outros, ";
            }
          });

          console.log(productNames);

          console.log("ESTRUTURA CONTRATO - PARTE 3");

          this.contrato.produtos = productNames.slice(0, -2);

          // PAGAMENTO

          let theBank = 0;

          // this.oldBanks.forEach((bank) => {
          //     if (
          //         bank.number ==
          //         parseInt(data.meta.payment_methods_bank_transfer_bank)
          //     ) {
          //         theBank = bank;
          //     }
          // });


          console.log("ESTRUTURA CONTRATO - PARTE 3.1");

          this.banks.forEach((bank) => {

            if (data.id < 196 && data.id != 43) {
              if (
                parseInt(bank.number) ==
                data.meta.payment_methods_bank_transfer_bank
              ) {
                theBank = bank;
              }
            } else {
              if (
                bank.account ==
                data.meta.payment_methods_bank_transfer_bank
              ) {
                theBank = bank;
              }
            }

          });

          console.log("ESTRUTURA CONTRATO - PARTE 3.2");

          this.contrato.pagamento.total = data.meta.reservation_total_price != undefined ? this.formatPrice(data.meta.reservation_total_price) : 0;

          if (data.meta.payment_methods.includes("bank-transfer")) {
            this.contrato.pagamento.transferencia.ativo = data.meta.payment_methods.includes(
              "bank-transfer"
            );
            this.contrato.pagamento.transferencia.banco = theBank;
            this.contrato.pagamento.transferencia.valor = this.formatPrice(
              data.meta.payment_methods_bank_transfer_total_value
            );
          }

          console.log("ESTRUTURA CONTRATO - PARTE 3.2.5");

          if (data.meta.payment_methods.includes("custom-payment")) {
            this.contrato.pagamento.personalizado.ativo = data.meta.payment_methods.includes(
              "custom-payment"
            );
            this.contrato.pagamento.personalizado.nome = data.meta.payment_methods_custom_payment_name;
            this.contrato.pagamento.personalizado.obs = data.meta.payment_methods_custom_payment_observation;
            this.contrato.pagamento.personalizado.valor = this.formatPrice(
              data.meta.payment_methods_custom_payment_total_value
            );
          }

          console.log("ESTRUTURA CONTRATO - PARTE 3.3");

          if (data.meta.payment_methods.includes("billing-ticket")) {
            console.log("ESTRUTURA CONTRATO - PARTE 3.3.1");

            this.contrato.pagamento.boleto.ativo = data.meta.payment_methods.includes(
              "billing-ticket"
            );

            this.contrato.pagamento.boleto.valor = this.formatPrice(
              data.meta.payment_methods_billing_ticket_total_value
            );


            this.contrato.pagamento.boleto.obs =
              data.meta.payment_methods_billing_ticket_observation;
            this.contrato.pagamento.boleto.parcelas =
              data.meta.payment_methods_billing_ticket_installments;
          }

          console.log("ESTRUTURA CONTRATO - PARTE 3.4");

          if (data.meta.payment_methods.includes("payment-card")) {
            this.contrato.pagamento.carta.ativo = data.meta.payment_methods.includes(
              "payment-card"
            );
            this.contrato.pagamento.carta.valor = this.formatPrice(
              data.meta.payment_methods_payment_card_total_value
            );
            this.contrato.pagamento.carta.id_voyage =
              data.meta.payment_methods_payment_card_voyage_id ? data.meta.payment_methods_payment_card_voyage_id : '';

            if (data.meta.payment_card_rows != undefined) {
              JSON.parse(data.meta.payment_card_rows).forEach((pCard) => {
                console.log("CARTA CREDITO");
                this.contrato.pagamento.carta.lista.push({
                  id: pCard.id,
                  valor: this.formatPrice(data.meta[`payment_methods_payment_card_total_value_${pCard.id}`]),
                  id_voyage: data.meta[`payment_methods_payment_card_voyage_id_${pCard.id}`] ? data.meta[`payment_methods_payment_card_voyage_id_${pCard.id}`] : ''
                })
              })
            }

          }

          console.log("ESTRUTURA CONTRATO - PARTE 3.5");

          this.contrato.pagamento.terceiros = {};

          this.contrato.pagamento.terceiros.ativo = data.meta.payment_methods.includes(
            "third-party"
          );

          console.log("ESTRUTURA CONTRATO - PARTE 4");

          this.contrato.pagamento.terceiros.lista = [];

          console.log("Terceiros");

          if (
            data.meta.payment_methods.includes("third-party") &&
            data.meta["third_party_payment_rows"] != undefined
          ) {

            console.log("Terceiros setup", data.meta.third_party_payment_rows);
            JSON.parse(data.meta.third_party_payment_rows).forEach((person) => {
              this.contrato.pagamento.terceiros.lista.push({
                id: person.id,
                check: true,
                nome: data.meta[
                    "third_party_client_first_name_" +
                    person.id
                  ].toUpperCase() +
                  " " +
                  data.meta[
                    "third_party_client_last_name_" +
                    person.id
                  ].toUpperCase(),
                nascimento: this.formatMultiDates(
                  data.meta[
                    "third_party_client_birthday_" +
                    person.id
                  ]
                ),
                cpf: data.meta[
                  "third_party_client_CPF_" + person.id
                ],
                orgao: data.meta[
                    "third_party_client_doc_emission_" +
                    person.id
                  ].toUpperCase() +
                  "-" +
                  data.meta[
                    "third_party_client_doc_emission_state_" +
                    person.id
                  ],
                rg: data.meta[
                  "third_party_client_RG_" + person.id
                ],
                telefone: data.meta[
                  "third_party_client_phone_" + person.id
                ] != undefined ? data.meta[
                  "third_party_client_phone_" + person.id
                ] : '',
                celular: data.meta[
                  "third_party_client_mobile_phone_" +
                  person.id
                ],
                email: data.meta[
                  "third_party_client_email_" + person.id
                ].toUpperCase(),
                rua: data.meta[
                  "third_party_client_location_street_" +
                  person.id
                ].toUpperCase(),
                numero: data.meta[
                  "third_party_client_location_number_" +
                  person.id
                ],
                bairro: data.meta[
                  "third_party_client_location_neighborhood_" +
                  person.id
                ] != undefined ? data.meta[
                  "third_party_client_location_neighborhood_" +
                  person.id
                ].toUpperCase() : '',
                complemento: data.meta[
                  "third_party_client_location_complement_" +
                  person.id
                ] != undefined ? data.meta[
                  "third_party_client_location_complement_" +
                  person.id
                ].toUpperCase() : '',
                estado: data.meta[
                  "third_party_client_location_state_" +
                  person.id
                ],
                cidade: data.meta[
                  "third_party_client_location_city_" +
                  person.id
                ].toUpperCase(),
                cep: data.meta[
                  "third_party_client_CEP_" + person.id
                ],
                valor: data.meta[
                  "third_party_client_paid_value_" + person.id
                ] != undefined ? 'R$ ' + this.formatPrice(data.meta[
                  "third_party_client_paid_value_" + person.id
                ]) : "",
              });
            });
          }

          console.log("CARTAO CREDITO");

          console.log("ESTRUTURA CONTRATO - PARTE 5");

          this.contrato.pagamento.credito.ativo = data.meta.payment_methods.includes(
            "credit-card"
          );

          console.log("ESTRUTURA CONTRATO - PARTE 5.2");

          if (data.meta['payment_methods']) {

            if (JSON.parse(data.meta.payment_methods).includes("credit-card")) {
              this.contrato.pagamento.credito.valor =
                data.meta.payment_methods_credit_card_total_value,
                this.contrato.pagamento.credito.cartao = [];

              if (JSON.parse(data.meta.credit_card_rows) != undefined) {
                JSON.parse(data.meta.credit_card_rows).forEach((card) => {
                  console.log("CARTAO CREDITO2");
                  this.contrato.pagamento.credito.cartao.push({
                    id: card.id,
                    check: true,
                    numeros: {
                      primeiro: "xxxx",
                      segundo: "xxxx",
                      terceiro: "xxxx",
                      quarto: "xxxx",
                    },
                    expiracao: `${
                                        data.meta[
                                            `payment_methods_credit_card_masked_month_${card.id}`
                                        ] != undefined
                                            ? data.meta[
                                                  `payment_methods_credit_card_masked_month_${card.id}`
                                              ]
                                            : ""
                                    }/${
                                        data.meta[
                                            `payment_methods_credit_card_masked_year_${card.id}`
                                        ] != undefined
                                            ? data.meta[
                                                  `payment_methods_credit_card_masked_year_${card.id}`
                                              ]
                                            : ""
                                    }`,
                    cvv: `${
                                        data.meta[
                                            `payment_methods_credit_card_masked_cvv_${card.id}`
                                        ] != undefined
                                            ? data.meta[
                                                  `payment_methods_credit_card_masked_cvv_${card.id}`
                                              ]
                                            : ""
                                    }`,
                    nome: `${
                                        data.meta[
                                            `payment_methods_credit_card_masked_name_${card.id}`
                                        ] != undefined
                                            ? data.meta[
                                                  `payment_methods_credit_card_masked_name_${card.id}`
                                              ].toUpperCase()
                                            : ""
                                    }`,
                    bandeira: `${
                                        data.meta[
                                            `payment_methods_credit_card_masked_flag_${card.id}`
                                        ] != undefined
                                            ? data.meta[
                                                  `payment_methods_credit_card_masked_flag_${card.id}`
                                              ].trim()
                                            : ""
                                    }`,
                    valor: `${
                                        data.meta[
                                            `payment_methods_credit_card_total_value_${card.id}`
                                        ] != undefined
                                            ? this.formatPrice(
                                                  data.meta[
                                                      `payment_methods_credit_card_total_value_${card.id}`
                                                  ]
                                              )
                                            : ""
                                    }`,
                    parcelas: `${
                                        data.meta[
                                            `payment_methods_credit_card_installments_${card.id}`
                                        ]
                                    }`,
                    primeira_parcela: `R$ ${
                                            data.meta[
                                            `payment_methods_credit_card_first_installment_value_${card.id}`
                                        ] 
                                    }`, //
                    demais_parcelas: `R$  ${
                                            data.meta[
                                            `payment_methods_credit_card_installments_value_${card.id}`
                                        ]
                                    }`,
                    taxas: `${
                                        data.meta[
                                            `payment_methods_credit_card_tax_value_${card.id}`
                                        ] != undefined
                                            ?  this.formatPrice(data.meta[
                                                  `payment_methods_credit_card_tax_value_${card.id}`
                                              ])
                                            : "<font style='color: transparent'>___</font>"
                                    }`,
                    proprietario: {
                      tipo: data.meta[
                        `payment_methods_credit_card_owner_${card.id}`
                      ] != undefined ? data.meta[
                        `payment_methods_credit_card_owner_${card.id}`
                      ] : 'client',
                      nome: data.meta[
                        `payment_methods_credit_card_owner_${card.id}_name`
                      ] != undefined ? data.meta[
                        `payment_methods_credit_card_owner_${card.id}_name`
                      ].toUpperCase() : '',
                      cpf: data.meta[
                        `payment_methods_credit_card_owner_${card.id}_CPF`
                      ] != undefined ? data.meta[
                        `payment_methods_credit_card_owner_${card.id}_CPF`
                      ] : '',
                      telefone: data.meta[
                        `payment_methods_credit_card_owner_${card.id}_phone`
                      ] != undefined ? data.meta[
                        `payment_methods_credit_card_owner_${card.id}_phone`
                      ] : '',
                      celular: data.meta[
                        `payment_methods_credit_card_owner_${card.id}_mobile_phone`
                      ] != undefined ? data.meta[
                        `payment_methods_credit_card_owner_${card.id}_mobile_phone`
                      ] : '',
                      endereco: {
                        rua: data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_street`
                        ] != undefined ? data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_street`
                        ].toUpperCase() : '',
                        numero: data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_number`
                        ] != undefined ? data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_number`
                        ].toUpperCase() : '',
                        bairro: data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_neighborhood`
                        ] != undefined ? data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_neighborhood`
                        ].toUpperCase() : '',
                        complement: data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_complement`
                        ] != undefined ? data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_complement`
                        ].toUpperCase() : '',
                        estado: data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_state`
                        ] != undefined ? data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_state`
                        ].toUpperCase() : '',
                        cidade: data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_city`
                        ] != undefined ? data.meta[
                          `payment_methods_credit_card_owner_${card.id}_location_city`
                        ].toUpperCase() : '',
                        cep: data.meta[
                          `payment_methods_credit_card_owner_${card.id}_CEP`
                        ] != undefined ? data.meta[
                          `payment_methods_credit_card_owner_${card.id}_CEP`
                        ].toUpperCase() : ''
                      }
                    }
                  });
                });
              }
            }

          }

          console.log("ESTRUTURA CONTRATO - PARTE 6");

          // VIAJANTES

          this.contrato.listaViajantes = {
            todos: [],
            hotel: [],
            voo: [],
            servico: []
          };

          this.contrato.viajantes = [];

          let hotels =
            data.meta["hotel_rows"] != undefined ?
            data.meta.hotel_rows : [];

          if (data.meta.travellers_rows != undefined) {
            JSON.parse(data.meta.travellers_rows).forEach(viajante => {
              this.contrato.listaViajantes.todos.push({
                "id": viajante.id,
                "nome": `${data.meta[`traveller_${viajante.id}_first_name`].toUpperCase()} ${data.meta[`traveller_${viajante.id}_last_name`].toUpperCase()}`,
                "nascimento": data.meta[`traveller_${viajante.id}_birthday`] != undefined ? this.formatMultiDates(data.meta[`traveller_${viajante.id}_birthday`]) : '',
                "cpf": `${data.meta[`traveller_${viajante.id}_CPF`] ? data.meta[`traveller_${viajante.id}_CPF`] : ''}`,
              })

            });
          }

          console.log("ESTRUTURA CONTRATO - PARTE 6.1");

          if (contracted_services.includes("flight")) {

            if (data.meta['flight_sections_rows'] != undefined) {

              console.log("ESTRUTURA CONTRATO - PARTE 6.2");

              let flight_sections_rows = JSON.parse(data.meta.flight_sections_rows);

              console.log('flight_sections_rows', flight_sections_rows);
              console.log("ESTRUTURA CONTRATO - PARTE 6.3");

              flight_sections_rows.forEach((flightSection, index) => {

                console.log("ESTRUTURA CONTRATO - PARTE 6.3.1", flightSection);

                this.contrato.aereo.tipo = "Novo Formato";
                this.contrato.aereo.voos.push({
                  id: flightSection.id,
                  valor: data.meta[`flight_${flightSection.id}_total_price`] ? 'R$ ' + this.formatPrice(
                    data.meta[`flight_${flightSection.id}_total_price`]
                  ) : '',
                  valor_extenso: data.meta[`flight_${flightSection.id}_total_price_written`] != undefined ?
                    data.meta[
                      `flight_${flightSection.id}_total_price_written`
                    ].toUpperCase() : "",
                  viajantes: [],
                  trechos: [],
                  cias: [],
                });

                console.log("ESTRUTURA CONTRATO - PARTE 6.3.2", flightSection, this.contrato.aereo.voos, index);

                // flightSection.airlines.forEach(airline => {

                //   this.contrato.aereo.voos[index].cias.push({
                //     razao_social: data.meta[
                //       `flight_${flightSection.id}_airline_${airline.id}`
                //     ].split('|')[3],
                //     nome_fantasia: data.meta[
                //       `flight_${flightSection.id}_airline_${airline.id}`
                //     ].split('|')[2],
                //     cnpj: data.meta[
                //       `flight_${flightSection.id}_airline_${airline.id}`
                //     ].split('|')[4],

                //   })

                // });

                console.log("ESTRUTURA CONTRATO - PARTE 6.3.3");

                flightSection.sections.forEach(section => {
                  this.contrato.aereo.voos[index].trechos.push({
                    id: section.id,
                    cia: data.meta[
                      `flight_${flightSection.id}_section_${section.id}_airline`
                    ].toUpperCase(),
                    // cia: data.meta[
                    //     `flight_${flightSection.id}_section_${section.id}_airline`
                    // ],
                    numero: data.meta[`flight_${flightSection.id}_section_${section.id}_number`],
                    paradas: data.meta[`flight_${flightSection.id}_section_${section.id}_stops`],
                    data_saida: this.formatMultiDates(
                      data.meta[
                        `flight_${flightSection.id}_section_${section.id}_departure_date`
                      ]
                    ),
                    horario_saida: data.meta[
                      `flight_${flightSection.id}_section_${section.id}_departure_time`
                    ],
                    data_chegada: this.formatMultiDates(
                      data.meta[
                        `flight_${flightSection.id}_section_${section.id}_arrival_date`
                      ]
                    ),
                    horario_chegada: data.meta[
                      `flight_${flightSection.id}_section_${section.id}_arrival_time`
                    ],
                    duracao: data.meta[
                      `flight_${flightSection.id}_section_${section.id}_duration`
                    ],
                    origem: data.meta[
                      `flight_${flightSection.id}_section_${section.id}_origin`
                    ].toUpperCase(),
                    destino: data.meta[
                      `flight_${flightSection.id}_section_${section.id}_destination`
                    ].toUpperCase(),
                    classe: data.meta[
                      `flight_${flightSection.id}_section_${section.id}_class`
                    ].toUpperCase(),
                  });
                });

                console.log("ESTRUTURA CONTRATO - PARTE 6.3.4");

                flightSection.travellers.forEach(traveller => {

                  if (this.contract.id < 373 && this.contract.id != 43) {

                    this.contrato.aereo.voos[index].viajantes.push({
                      id: traveller.id,
                      nome: data.meta[
                          "flight_" + flightSection.id + "_person_first_name_" +
                          traveller.id
                        ].toUpperCase() +
                        " " +
                        data.meta[
                          "flight_" + flightSection.id + "_person_last_name_" +
                          traveller.id
                        ].toUpperCase(),
                      nascimento: this.formatMultiDates(
                        data.meta[
                          "flight_" + flightSection.id + "_person_birthday_" + traveller.id
                        ]
                      ),
                      cpf: data.meta[
                        "flight_" + flightSection.id + "_person_CPF_" + traveller.id
                      ],
                      valor: 'R$ ' + this.formatPrice(data.meta[
                        "flight_" + flightSection.id + "_person_price_" + traveller.id
                      ]),
                    });

                    this.contrato.viajantes.push({
                      id: traveller.id,
                      nome: data.meta[
                          "flight_" + flightSection.id + "_person_first_name_" +
                          traveller.id
                        ].toUpperCase().trim() +
                        " " +
                        data.meta[
                          "flight_" + flightSection.id + "_person_last_name_" +
                          traveller.id
                        ].toUpperCase().trim(),
                      nascimento: this.formatMultiDates(
                        data.meta[
                          "flight_" + flightSection.id + "_person_birthday_" + traveller.id
                        ]
                      ),
                      cpf: data.meta[
                        "flight_" + flightSection.id + "_person_CPF_" + traveller.id
                      ],
                      valor: data.meta[
                        "flight_" + flightSection.id + "_person_price_" + traveller.id
                      ] ? 'R$ ' + this.formatPrice(data.meta[
                        "flight_" + flightSection.id + "_person_price_" + traveller.id
                      ]) : '',
                    });

                  } else {
                    // this.contrato.listaViajantes.todos.forEach(viajante => {
                    //  if (viajante.id == data.meta[`flight_${flightSection.id}_traveller_${traveller.id}`]) {

                    //let viajante = {};
                    // let theTraveller = {};
                    let currency = 'R$';

                    if (data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_taxes_currency`] != undefined) {
                      currency = this.formatCurrency(data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_taxes_currency`]);
                    }

                    console.log('ESTRUTURA CONTRATO - PARTE 6.3.5', data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_value`]);

                    // viajante.valor = data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_value`] != undefined ? this.formatPrice(data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_value`]) : '';

                    // viajante.taxas = `${currency} ${data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_taxes`] != undefined ? data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_taxes`] : '0,00'}`;

                    this.contrato.listaViajantes.todos.forEach(viajante => {
                      if (viajante.id == data.meta[`flight_${flightSection.id}_traveller_${traveller.id}`]) {
                        //
                        this.contrato.listaViajantes.voo.push(viajante);
                        this.contrato.aereo.voos[index].viajantes.push({
                          ...viajante,
                          valor: data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_value`] != undefined ? this.formatPrice(data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_value`]) : '',
                          taxas: `${currency} ${data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_taxes`] != undefined ? data.meta[`flight_${flightSection.id}_traveller_${traveller.id}_taxes`] : '0,00'}`
                        });
                      }
                    });

                    // this.contrato.listaViajantes.voo.push(viajante);
                    // this.contrato.aereo.voos[index].viajantes.push(viajante);
                    //  }
                    //  });
                  }

                });

              });

            } else {

              console.log("ESTRUTURA CONTRATO - PARTE 6.2");

              let flight_travellers =
                data.meta["flight_travellers"] != undefined ?
                data.meta.flight_travellers : [];

              let flight_rows =
                data.meta["flight_rows"] != undefined ?
                JSON.parse(data.meta.flight_rows) : [];

              if (contracted_services.includes("flight")) {
                this.contrato.aereo.voos = [];
                flight_rows.forEach((flight) => {
                  this.contrato.aereo.voos.push({
                    id: flight.id,
                    cia: data.meta[
                      `flight_${flight.id}_airline`
                    ].toUpperCase(),
                    numero: data.meta[`flight_${flight.id}_number`],
                    paradas: data.meta[`flight_${flight.id}_stops`],
                    data: this.formatMultiDates(
                      data.meta[
                        `flight_${flight.id}_departure_date`
                      ]
                    ),
                    horario: data.meta[
                      `flight_${flight.id}_departure_time`
                    ],
                    origem: data.meta[
                      `flight_${flight.id}_origin`
                    ].toUpperCase(),
                    destino: data.meta[
                      `flight_${flight.id}_destination`
                    ].toUpperCase(),
                    classe: data.meta[
                      `flight_${flight.id}_class`
                    ].toUpperCase(),
                  });
                });
              }

              console.log("ESTRUTURA CONTRATO - PARTE 7.2");

              let theAirlines = [];

              if (contracted_services.includes("flight")) {

                if (data.meta[`flight_airlines`] != undefined) {
                  data.meta[`flight_airlines`].forEach((airline) => {
                    theAirlines.push({
                      razao_social: data.meta[
                        `flight_airline_company_name_${airline.id}`
                      ].toUpperCase(),
                      nome_fantasia: data.meta[
                        `flight_airline_trading_name_${airline.id}`
                      ].toUpperCase(),
                      cnpj: data.meta[
                        `flight_airline_CNPJ_${airline.id}`
                      ],
                    });
                  });
                }

              }

              this.contrato.aereo = {
                ...this.contrato.aereo,
                valor: data.meta[`flight_total_price`] ? 'R$ ' + this.formatPrice(
                  data.meta[`flight_total_price`]
                ) : '',
                valor_extenso: data.meta[`flight_total_price_written`] != undefined ?
                  data.meta[
                    `flight_total_price_written`
                  ].toUpperCase() : "",
                cias: theAirlines,
              };

              if (contracted_services.includes("flight")) {
                flight_travellers.forEach((traveller) => {
                  this.contrato.viajantes.push({
                    id: traveller.id,
                    nome: data.meta[
                        "flight_person_first_name_" +
                        traveller.id
                      ].toUpperCase().trim() +
                      " " +
                      data.meta[
                        "flight_person_last_name_" +
                        traveller.id
                      ].toUpperCase().trim(),
                    nascimento: this.formatMultiDates(
                      data.meta[
                        "flight_person_birthday_" + traveller.id
                      ]
                    ),
                    cpf: data.meta[
                      "flight_person_CPF_" + traveller.id
                    ],
                    valor: 'R$ ' + this.formatPrice(data.meta[
                      "flight_person_price_" + traveller.id
                    ]),
                  });
                });
              }

              console.log("Test 2", flight_rows);

            }

          }

          let service_travellers =
            data.meta["service_travellers"] != undefined ?
            JSON.parse(data.meta.service_travellers) : [];

          let service_rows =
            data.meta["service_rows"] != undefined ?
            JSON.parse(data.meta.service_rows) : [];


          console.log("ESTRUTURA CONTRATO - PARTE 7.1", contracted_services, service_rows);

          if (contracted_services.includes("service")) {
            service_rows.forEach((service) => {
              this.contrato.listaServicos.push({
                id: service.id,
                nome: `${data.meta[
                                    `service_${service.id}_type`
                                ] != undefined ? data.meta[
                                    `service_${service.id}_type`
                                ] + ' - ' : ''} ${ data.meta[
                                    `service_${service.id}_name`
                                ].toUpperCase()}`,
                valor: data.meta[
                  `service_${service.id}_total_price`
                ] != undefined ? 'R$ ' + this.formatPrice(data.meta[
                  `service_${service.id}_total_price`
                ]) : '',
              });
            });
          }

          console.log("ESTRUTURA CONTRATO - PARTE 7.4", hotels, contracted_services);

          if (contracted_services.includes("hotel")) {
            JSON.parse(hotels).forEach((hotel) => {
              let accommodations = [];


              hotel.accommodation.forEach((room) => {
                let allTravellers = [];

                room.travellers.forEach((traveller) => {
                  this.contrato.listaViajantes.todos.forEach(viajante => {
                    if (viajante.id == data.meta[`hotel_${ hotel.id}_accommodation_${room.id}_traveller_${traveller.id}`]) {
                      allTravellers.push(viajante)
                    }
                  });
                });

                accommodations.push({
                  id: room.id,
                  nome: data.meta[
                    "hotel_" +
                    hotel.id +
                    "_accommodation_" +
                    room.id
                  ].toUpperCase(),
                  tipo: data.meta[
                    "hotel_" +
                    hotel.id +
                    "_accommodation_" +
                    room.id +
                    "_type"
                  ],
                  hospedes: allTravellers
                });

                console.log("ESTRUTURA CONTRATO - PARTE 7.6", room.travellers);

                room.travellers.forEach((traveller) => {
                  console.log(traveller);

                  console.log("ESTRUTURA CONTRATO - PARTE 7.8", traveller, );

                  this.contrato.listaViajantes.todos.forEach(viajante => {
                    if (viajante.id == data.meta[`hotel_${ hotel.id}_accommodation_${room.id}_traveller_${traveller.id}`]) {
                      this.contrato.listaViajantes.hotel.push(viajante);
                    }
                  });

                  this.contrato.viajantes.push({
                    id: traveller.id,
                    nome: data.meta[
                        "hotel_" +
                        hotel.id +
                        "_accommodation_" +
                        room.id +
                        "_person_first_name_" +
                        traveller.id
                      ] != undefined ? data.meta[
                        "hotel_" +
                        hotel.id +
                        "_accommodation_" +
                        room.id +
                        "_person_first_name_" +
                        traveller.id
                      ].toUpperCase().trim() +
                      " " +
                      data.meta[
                        "hotel_" +
                        hotel.id +
                        "_accommodation_" +
                        room.id +
                        "_person_last_name_" +
                        traveller.id
                      ].toUpperCase().trim() : '',
                    nascimento: data.meta[
                      "hotel_" +
                      hotel.id +
                      "_accommodation_" +
                      room.id +
                      "_person_birthday_" +
                      traveller.id
                    ] != undefined ? this.formatMultiDates(
                      data.meta[
                        "hotel_" +
                        hotel.id +
                        "_accommodation_" +
                        room.id +
                        "_person_birthday_" +
                        traveller.id
                      ]
                    ) : '',
                    cpf: data.meta[
                      "hotel_" +
                      hotel.id +
                      "_accommodation_" +
                      room.id +
                      "_person_CPF_" +
                      traveller.id
                    ],
                  });
                });
              });

              console.log("ESTRUTURA CONTRATO - PARTE 7.9",
                this.contrato.listaHoteis);

              this.contrato.listaHoteis.push({
                id: hotel.id,
                nome: data.meta[
                  "hotel_" + hotel.id + "_name"
                ].toUpperCase(),
                regime: data.meta[
                  "hotel_" + hotel.id + "_diet"
                ].toUpperCase(),
                checkin: this.formatMultiDates(
                  data.meta["hotel_" + hotel.id + "_checkin"]
                ),
                checkout: this.formatMultiDates(
                  data.meta["hotel_" + hotel.id + "_checkout"]
                ),
                noites: data.meta["hotel_" + hotel.id + "_rates"],
                valor: data.meta["hotel_" + hotel.id + "_total_price"] != undefined ? 'R$ ' + this.formatPrice(data.meta["hotel_" + hotel.id + "_total_price"]) : '',
                acomodacoes: accommodations,
              });

            });
          }

          console.log("ESTRUTURA CONTRATO - PARTE 8");

          if (contracted_services.includes("service")) {
            service_travellers.forEach((traveller) => {

              this.contrato.listaViajantes.todos.forEach(viajante => {
                if (viajante.id == data.meta[`service_traveller_${traveller.id}`]) {
                  this.contrato.listaViajantes.servico.push(viajante);
                }
              });

              this.contrato.viajantes.push({
                id: traveller.id,
                nome: data.meta[
                    "service_person_last_name_" +
                    traveller.id
                  ] != undefined ? data.meta[
                    "service_person_first_name_" +
                    traveller.id
                  ].toUpperCase().trim() +
                  " " +
                  data.meta[
                    "service_person_last_name_" +
                    traveller.id
                  ].toUpperCase().trim() : '',
                nascimento: data.meta[
                  "service_person_birthday_" +
                  traveller.id
                ] != undefined ? this.formatMultiDates(
                  data.meta[
                    "service_person_birthday_" +
                    traveller.id
                  ]
                ) : '',
                cpf: data.meta[
                  "service_person_CPF_" + traveller.id
                ],
              });
            });
          }

          if (parseInt(this.contrato.id) > 373) {
            this.contrato.viajantes = this.contrato.listaViajantes.todos;
          }

          console.log("via", this.contrato.viajantes);

          console.log("ESTRUTURA CONTRATO - PARTE 9");

          function removeDuplicates(arr, equals) {
            var originalArr = arr.slice(0);
            var i, len, val;
            arr.length = 0;

            for (i = 0, len = originalArr.length; i < len; ++i) {
              val = originalArr[i];
              if (
                !arr.some(function (item) {
                  return equals(item, val);
                })
              ) {
                arr.push(val);
              }
            }
          }

          function thingsEqual(thing1, thing2) {
            return (
              thing1.nome == thing2.nome &&
              thing1.nascimento == thing2.nascimento
            );
          }

          removeDuplicates(this.contrato.viajantes, thingsEqual);

          // NOVA VERSAO DAS POLITICAS CONTRATOS ACIMA DO ID 2262
          if (parseInt(data.id) > 2262) {
            console.log("ESTRUTURA CONTRATO - PARTE 10", parseInt(data.id), this.policy.list);

            this.contrato.politicas = this.policy.list
            //reservation_policies
            //
          } else {

            let find = ["<p><br></p>"];
            let replace = [""];
            let policiesDB = data.meta.reservation_policies;

            console.log("ESTRUTURA CONTRATO - PARTE 10", parseInt(data.id), policiesDB);

            if (parseInt(data.id) < 371 && parseInt(data.id) != 43) {

              console.log("ESTRUTURA CONTRATO - PARTE 10.1");

              if (policiesDB) {
                policiesDB = atob(policiesDB);
                JSON.parse(policiesDB).forEach((policy) => {
                  if (policy.showInContract) {
                    this.contrato.politicas.push({
                      titulo: policy.title.toUpperCase(),
                      texto: replaceOnce(
                        policy.text,
                        find,
                        replace,
                        "gi"
                      ),
                    });
                  }
                });
              }

            } else {

              // console.log("ESTRUTURA CONTRATO - PARTE 10.2", JSON.parse(policiesDB));

              if (policiesDB) {
                console.log("ESTRUTURA CONTRATO - PARTE 10.5", policiesDB);
                JSON.parse(policiesDB).forEach((policy) => {
                  console.log("ESTRUTURA CONTRATO - PARTE 10.6", policy);
                  if (policy.showInContract) {
                    console.log("ESTRUTURA CONTRATO - PARTE 10.7");
                    this.contrato.politicas.push({
                      titulo: policy.title.toUpperCase(),
                      texto: replaceOnce(
                        policy.text,
                        find,
                        replace,
                        "gi"
                      ),
                    });
                  }
                });
              }

            }

          }

          console.log("ESTRUTURA CONTRATO - PARTE 11");

          this.contrato.data = format(new Date(), "dd/MM/yyyy HH:mm");

          setTimeout(() => {
            this.docSizes.contractDetailsSize = document.getElementById(
              "contract-details"
            ).clientHeight;
            console.log(
              "contractDetails",
              document.getElementById("contract-details")
              .clientHeight
            );
          }, 100);

          console.log("ESTRUTURA CONTRATO - PARTE 12");

          let promises = [];

          console.log("ESTRUTURA CONTRATO - PARTE 12.5", data.meta.contract_documents, JSON.parse(data.meta.contract_documents), this.$route.path.split('/')[1]);

          if (this.$route.path.split('/')[1] == "contracts") {

            JSON.parse(data.meta.contract_documents).forEach((document) => {

              console.log('getDocumentContractView', document);
              if (document == "7" || document == "8" || document == "9" || document == "10") {

                let title = "";
                let subpages = [];

                if (document == "9") {
                  title = "(SISTEMA) Autorização de Débito"
                  subpages = this.contrato.pagamento.credito.cartao;
                }

                if (document == "10") {
                  title = "(SISTEMA) Anexo II - Terceiros"
                  subpages = this.contrato.pagamento.terceiros.lista
                }

                if (document == "8") {
                  title = "(SISTEMA) VR - Anexo - Produtos"
                }

                if (document == "7") {
                  title = "(SISTEMA) VR - Contrato 2021"
                }

                this.contractPages.push({
                  id: document,
                  title: title,
                  subtitle: '',
                  data: [],
                  check: true,
                  subpages
                })

              } else {
                promises.push(this.$http
                  .post(`/document/details?id=${document}`));
              }
            })

            console.log('getDocumentContractView promises', promises);

            Promise.all(promises)
              .then((list) => {
                console.log('getDocumentContractView list 1', list);

                list.forEach(doc => {
                  doc;

                  console.log('getDocumentContractView list 2', doc);

                  let paragraphs = decodeURIComponent(escape(window.atob(doc.data.structure)));
                  let newParagraphs = [];

                  console.log('getDocumentContractView list 2.5', JSON.parse(paragraphs));

                  JSON.parse(paragraphs).forEach((paragraph) => {
                    newParagraphs.push({
                      id: paragraph.id,
                      p: this.renderTags(paragraph.p),
                      css: paragraph.css,
                      page: paragraph.page
                    });
                  });

                  console.log('getDocumentContractView list 3', newParagraphs);

                  this.contractPages.push({
                    id: doc.data.id,
                    title: doc.data.title,
                    subtitle: doc.data.subtitle,
                    company_branch: doc.data.company_branch,
                    check: true,
                    data: newParagraphs
                  });
                  this.document_paragraph = newParagraphs;
                  this.document = doc.data;

                  console.log(' contractPages 2', this.contractPages, this.contract.meta.contracted_services);

                  // let theProducts = 

                  this.contractPages.forEach((page, index) => {
                    if (page.id == 11 || page.id == 4) {
                      console.log(' contractPages 3', this.contractPages[index].data);
                      this.contractPages[index].data.forEach(() => {

                        if (this.contractPages[index].data.length > 4 && this.contract.meta.contracted_services.includes('hotel') && this.contract.meta.contracted_services.includes('flight') && this.contract.meta.contracted_services.includes('service') || this.contractPages[index].data.length > 4 && this.contract.meta.contracted_services.includes('hotel') && this.contract.meta.contracted_services.includes('flight')) {
                          this.contractPages[index].data[this.contractPages[index].data.length - 1].page = 2;
                        }
                      })
                    }
                  })

                  console.log(' contractPages after', this.contractPages);
                });

              })
              .catch((error) => {
                error
              });


            this.form.setFieldsValue({
              contract_documents: JSON.parse(data.meta.contract_documents),
            });

            const sorter = (a, b) => {
              return JSON.parse(data.meta.contract_documents).indexOf(a.id) - JSON.parse(data.meta.contract_documents).indexOf(b.id);
            };

            setTimeout(() => {
              this.contractPages.sort(sorter);
            }, 1000);

          } else {
            this.getDocument(this.$route.params.id);
          }

          console.log("CONTRATO FINALIZADO");

          setTimeout(() => {
            this.birdLoading = false
          }, 1000);

        })
        .catch(({
          response
        }) => {
          response
          // this.$message.error(response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });

      doc.html(document.body, {
        callback: function (doc) {
          doc;
        },
      });
    },
    getDocument(id) {
      this.$http
        .post(`/document/details?id=${id}`)
        .then(({
          data
        }) => {
          let paragraphs = decodeURIComponent(escape(window.atob(data.structure)));
          let newParagraphs = [];
          JSON.parse(paragraphs).forEach((paragraph) => {
            newParagraphs.push({
              id: paragraph.id,
              p: this.renderTags(paragraph.p),
              css: paragraph.css,
              page: paragraph.page
            });
          });

          console.log('duplicate', newParagraphs);
          this.document_paragraph = newParagraphs;
          this.document = data;
        })
        .catch(({
          response
        }) => {
          response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDocuments() {
      this.$http
        .get(
          `/document/list?page=1&per_page=100&company_branch_id=${this.contract.company_branch_id}&status=1&is_general=1&html=no`
        )
        .then(({
          data
        }) => {
          this.documents.documentsSelector = data.data;

          this.documents.documentsSelector.push({
            id: "10",
            name: `Anexo II - Terceiros `,
            company: "all",
            is_general: 1,
          });

          this.documents.documentsSelector.push({
            id: "9",
            name: `(SISTEMA) Autorização de Débito`,
            company: "all",
            is_general: 1,
          });
        });
    },
    getDocumentContractView(id) {
      this.$http
        .post("/document/read_one", {
          id: id,
        })
    },
    filterParagraphCols(paragraphs) {
      let breakPages = _.groupBy(paragraphs, (item) => item.page);
      let values = Object.values(breakPages);
      let keys = Object.keys(breakPages);
      let allPages = keys.map((travel, index) => {
        return {
          page: travel,
          paragraphs: values[index],
        };
      });

      console.log("filterParagraphCols all", allPages);

      allPages = allPages.map((page) => {
        return {
          ...page,
          paragraphs: {
            cols1: _.groupBy(
              page.paragraphs,
              (item) => item.css.cols
            )["1"],
            cols2: _.groupBy(
              page.paragraphs,
              (item) => item.css.cols
            )["2"],
          },
        };
      });

      console.log("filterParagraphCols all out", allPages);

      return allPages;
    },
  }
}