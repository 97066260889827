<template>
  <div class="documents view pd-20 ">
    <a-page-header class="travel-header pd-0 mb-0" @back="() => $router.go(-1)">
      <div slot="title">
        <h1>
          {{ document.name }}
          <a-tooltip placement="top" title="Editar documento">
            <a class="edit ml-20" @click="edit(document.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </h1>
      </div>
      <template slot="extra"> </template>
    </a-page-header>

    <a-row class="contract view">
      <a-col class="sidebar" :span="5">
        <a-anchor class="travel-anchor" @change="smoothScroll">
          <div class="title">Documentos</div>
          <a-anchor-link href="#contrato-viagem" :title="document.title" />
          <div class="action">
            <div class="">
              <a-button
                size="default"
                type="primary"
                ghost
                @click="printDocs(document.title)"
              >
                <a-icon type="printer" />
                IMPRIMIR
              </a-button>

              <a-button
                class="ml-10"
                @click="generatePDF(document.title)"
                size="default"
                type="danger"
                :loading="generatePDFLoading"
                ghost
              >
                <a-icon type="file-pdf" /> GERAR PDF
              </a-button>
            </div>
          </div>
        </a-anchor>

        <a-alert
          v-if="contractId > 0"
          class="mt-20"
          type="warning"
          :message="`Testando o contrato #${contract.id}.`"
          banner
          style="font-size: 11px; font-weight: 500"
        />

        <a-alert
          class="mt-20"
          type="info"
          message="DICA 1: Testar os dois botões acima (Imprimir e PDF) antes de liberar o template para utilização."
          banner
          style="font-size: 11px; font-weight: 500"
        />

        <a-alert
          class="mt-20"
          type="info"
          message="DICA 2: Sempre deixe um bom espaço vazio ao final de cada página, para posteriormente inserir algum rodapé e principalmente evitar quebras de páginas de forma errada."
          banner
          style="font-size: 11px; font-weight: 500"
        />
        <!-- <img src="../../assets/images/mastercard.png" style="width: 100%" /> -->
        <!-- <pre>{{ document_paragraph }}</pre> -->
        <!-- <pre> {{ contrato }} </pre> -->

        <a-modal
          title="Verificação de usuário"
          :visible="showPasswordModal"
          :footer="null"
          class="travel-modal"
          width="400px"
        >
          <a-row :gutter="[20, 0]">
            <a-col :span="16">
              <input
                name="email"
                style="
                  color: #fff;
                  opacity: 0;
                  pointer-events: none;
                  height: 0;
                  padding: 0;
                  border: 0;
                "
                placeholder="Fake email fix"
              />
              <a-input
                class="travel-input"
                size="large"
                v-model="userPassword"
                type="password"
                placeholder="Insira sua senha"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-col>
            <a-col class="a-right" :span="8">
              <a-button
                @click="getCardData"
                class="mt-30"
                type="primary"
                :loading="getCardDataLoading"
              >
                Ver dados
              </a-button>
            </a-col>
          </a-row>
        </a-modal>
      </a-col>
      <a-col
        id="document-viewer"
        class="document-viewer"
        :offset="1"
        :span="18"
      >
        <div id="scroll-top"></div>
        <div id="the-outer-document">
          <div class="contract-table">
            <div
              v-for="(a4, index) in filterParagraphCols(document_paragraph)"
              :key="index"
            >
              <table id="a4" :class="'a4'" width="100%" border="0">
                <tbody>
                  <tr>
                    <td class="td">
                      <table v-if="a4.page == '1'" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td valign="top" style="padding-top: 5px">
                              <img
                                :src="
                                  contract.company_branch.logo
                                    ? contract.company_branch.logo
                                    : page.company_branch.logo
                                "
                                :alt="contract.company_branch.name"
                                style="height: 50px; margin-bottom: 5px"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table v-if="a4.page == '1'" width="100%" border="0">
                        <tbody>
                          <tr v-if="document.title">
                            <td
                              align="center"
                              style="
                                font-size: 16px;
                                line-height: 1.2;
                                padding-bottom: 5px !important;
                                color: #666;
                              "
                            >
                              <strong
                                style="font-weight: 600"
                                v-html="document.title"
                              ></strong>
                            </td>
                          </tr>
                          <tr v-if="document.subtitle">
                            <td
                              align="center"
                              style="
                                font-size: 16px;
                                padding-bottom: 15px;
                                color: #666;
                              "
                            >
                              <strong
                                style="font-weight: 600"
                                v-html="document.subtitle"
                              ></strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div style="column-count: 1">
                        <div
                          v-for="(p, index) in a4.paragraphs.cols1"
                          :key="index"
                        >
                          <div
                            :style="{
                              fontSize:
                                p.css != undefined
                                  ? p.css.font.size
                                  : 'inherit',
                              borderStyle: p.css.borderWidth.top ? 'solid' : '',
                              borderColor:
                                p.css != undefined
                                  ? p.css.boderColor
                                  : 'inherit',
                              borderWidth:
                                p.css != undefined
                                  ? `${p.css.borderWidth.top} ${p.css.borderWidth.right} ${p.css.borderWidth.bottom} ${p.css.borderWidth.left}`
                                  : 'inherit',
                              marginTop:
                                p.css != undefined
                                  ? p.css.margin.top
                                  : 'inherit',
                              marginRight:
                                p.css != undefined
                                  ? p.css.margin.right
                                  : 'inherit',
                              marginBottom:
                                p.css != undefined
                                  ? p.css.margin.bottom
                                  : 'inherit',
                              marginLeft:
                                p.css != undefined
                                  ? p.css.margin.left
                                  : 'inherit',
                              paddingTop:
                                p.css != undefined
                                  ? p.css.padding.top
                                  : 'inherit',
                              paddingRight:
                                p.css != undefined
                                  ? p.css.padding.right
                                  : 'inherit',
                              paddingBottom:
                                p.css != undefined
                                  ? p.css.padding.bottom
                                  : 'inherit',
                              paddingLeft:
                                p.css != undefined
                                  ? p.css.padding.left
                                  : 'inherit',
                            }"
                            v-html="p.p"
                          ></div>
                        </div>
                      </div>

                      <div style="column-count: 2">
                        <div
                          v-for="(p, index) in a4.paragraphs.cols2"
                          :key="index"
                        >
                          <div
                            :style="{
                              fontSize:
                                p.css != undefined
                                  ? p.css.font.size
                                  : 'inherit',
                              borderStyle: p.css.borderWidth.top ? 'solid' : '',
                              borderColor:
                                p.css != undefined
                                  ? p.css.boderColor
                                  : 'inherit',
                              borderWidth:
                                p.css != undefined
                                  ? `${p.css.borderWidth.top} ${p.css.borderWidth.right} ${p.css.borderWidth.bottom} ${p.css.borderWidth.left}`
                                  : 'inherit',
                              marginTop:
                                p.css != undefined
                                  ? p.css.margin.top
                                  : 'inherit',
                              marginRight:
                                p.css != undefined
                                  ? p.css.margin.right
                                  : 'inherit',
                              marginBottom:
                                p.css != undefined
                                  ? p.css.margin.bottom
                                  : 'inherit',
                              marginLeft:
                                p.css != undefined
                                  ? p.css.margin.left
                                  : 'inherit',
                              paddingTop:
                                p.css != undefined
                                  ? p.css.padding.top
                                  : 'inherit',
                              paddingRight:
                                p.css != undefined
                                  ? p.css.padding.right
                                  : 'inherit',
                              paddingBottom:
                                p.css != undefined
                                  ? p.css.padding.bottom
                                  : 'inherit',
                              paddingLeft:
                                p.css != undefined
                                  ? p.css.padding.left
                                  : 'inherit',
                            }"
                            v-html="p.p"
                          ></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="page-break-after: always"></div>
              <div class="html2pdf__page-break avoidThisRow"></div>
            </div>
          </div>
        </div>
      </a-col>

      <a-col :offset="6" :span="18">
        <div id="totop">
          <a-button
            type="link"
            shape="circle"
            icon="up-circle"
            @click="toTop"
          />
        </div>
      </a-col>
    </a-row>
    <!-- <pre> {{ contrato }}  </pre> -->
  </div>
</template>

<script>
import { format, parse } from "date-fns";
import jq from "jquery";
import html2pdf from "html2pdf.js";

import renderDocumentVariables from "@/mixins/documents/renderDocumentVariables.js";
import viewContractData from "@/components/contracts/mixins/viewContractData";

export default {
  mixins: [renderDocumentVariables, viewContractData],
  data() {
    return {
      document: {},
      contract: {},
      document_paragraph: [],
      contractId: 0,
      showPasswordModal: false,
      userPassword: "",
      getCardDataLoading: false,
      generatePDFLoading: false,
      banks: [
        {
          name: "Banco Bradesco",
          number: 237,
          agency: "1246",
          account: "15600-0",
        },
        {
          name: "Banco Safra S.A",
          number: 422,
          agency: "0023",
          account: "395014-1",
        },
        {
          name: "Banco Cooperativo do Brasil",
          number: 756,
          agency: "3166",
          account: "50340-1",
        },
      ],
    };
  },
  filters: {
    formatPrice: function (price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
  },
  beforeMount() {
    this.$http
      .post("/document/read_one", {
        id: this.$route.params.id,
      })
      .then(({ data }) => {
        this.document = data;
        this.contractId = data.contract_id ? data.contract_id : 0;
        let paragraphs = decodeURIComponent(
          escape(window.atob(data.structure))
        );

        let newParagraphs = [];
        JSON.parse(paragraphs).forEach((paragraph) => {
          //  console.log(paragraph);
          newParagraphs.push({
            id: paragraph.id,
            p: paragraph.p,
            css: paragraph.css,
            page: paragraph.page,
          });
        });

        console.log("newParagraphs", newParagraphs);
        this.document_paragraph = newParagraphs;

        document.title = data.name;

        if (this.contractId > 0) {
          this.getContract();
        }
      })
      .catch(({ response }) => {
        response;
      })
      .finally(() => {});
  },
  methods: {
    toTop() {
      jq(".document-viewer").animate(
        {
          scrollTop: document.getElementById("scroll-top").offsetTop - 60,
        },
        500
      );
    },
    smoothScroll(e) {
      if (e) {
        let elementTop = document.getElementById(e.replace("#", "")).offsetTop;

        console.log("smooth", e, elementTop);

        jq(".document-viewer").animate(
          {
            scrollTop: elementTop,
          },
          1000
        );
      }
    },
    printDocs(docName) {
      if (this.contractId > 0) {
        document.title = `${docName} - ${this.contract.meta.client_name.toUpperCase()}`;
      } else {
        document.title = `${this.document.title}`;
      }

      if (this.contrato.pagamento.credito.ativo) {
        if (
          this.contract.meta["payment_methods_credit_card_client_name_1"] &&
          this.contract.meta["payment_methods_credit_card_client_month_1"] &&
          this.contract.meta["payment_methods_credit_card_client_year_1"]
        ) {
          window.print();
        } else {
          this.showPasswordModal = true;
          this.$message.warning(
            "Você está prestes a imprimir o documento sem exibir os dados de cartão do cliente. Insira sua senha para exibir os dados."
          );
        }
      } else {
        window.print();
      }

      document.title = `${
        this.contract.id
      } - ${this.contract.meta.client_name.toUpperCase()}`;
    },
    generatePDF(docName) {
      this.generatePDFLoading = true;

      let element = document.getElementById("the-outer-document");
      let options = {
        filename:
          this.contractId > 0
            ? `${docName} - ${this.contract.meta.client_name.toUpperCase()}.pdf`
            : this.document.title,
        image: { type: "jpeg", quality: 0.3 },
        enableLinks: false,
        margin: [8, 8, 8, 8],
        html2canvas: { scale: 2 },
        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
      };

      if (this.contrato.pagamento.credito.ativo) {
        if (
          this.contract.meta["payment_methods_credit_card_client_name_1"] &&
          this.contract.meta["payment_methods_credit_card_client_month_1"] &&
          this.contract.meta["payment_methods_credit_card_client_year_1"]
        ) {
          html2pdf().from(element).set(options).save();
          this.generatePDFLoading = false;
        } else {
          this.showPasswordModal = true;
          this.$message.warning(
            "Você está gerando o PDF sem exibir os dados de cartão do cliente. Insira sua senha para exibir os dados."
          );
          this.generatePDFLoading = false;
        }
      } else {
        html2pdf().from(element).set(options).save();

        this.generatePDFLoading = false;
      }
    },
    getCardData() {
      // INSERIU A SENHA
      if (this.userPassword) {
        this.getCardDataLoading = true;
        this.$http
          .post("/contract/read_card", {
            email: this.$store.state.userData.email,
            password: this.userPassword,
            contract_id: this.contract.id,
            card_id: 1,
          })
          .then(({ data }) => {
            this.$message.success(data.message);

            this.contract.meta = {
              ...this.contract.meta,
              ...data.card,
            };

            this.updateTheCardData = true;

            this.contract.meta.credit_card_rows.forEach((card, index) => {
              this.contrato.pagamento.credito.cartao[index].expiracao = `${
                data.card[`payment_methods_credit_card_client_month_${card.id}`]
              } / ${data.card[
                `payment_methods_credit_card_client_year_${card.id}`
              ].slice(-2)}`;

              this.contrato.pagamento.credito.cartao[index].cvv = `${
                data.card[
                  `payment_methods_credit_card_client_cvv_${card.id}`
                ] != undefined
                  ? data.card[
                      `payment_methods_credit_card_client_cvv_${card.id}`
                    ]
                  : ""
              } `;

              this.contrato.pagamento.credito.cartao[index].nome = `${
                data.card[`payment_methods_credit_card_client_name_${card.id}`]
              } `;

              this.contrato.pagamento.credito.cartao[
                index
              ].bandeira = `${data.card[
                `payment_methods_credit_card_client_flag_${card.id}`
              ].trim()}`;

              this.contrato.pagamento.credito.cartao[index].numeros.primeiro =
                data.card[
                  `payment_methods_credit_card_client_number_${card.id}`
                ].split(" ")[0];

              this.contrato.pagamento.credito.cartao[index].numeros.segundo =
                data.card[
                  `payment_methods_credit_card_client_number_${card.id}`
                ].split(" ")[1];

              this.contrato.pagamento.credito.cartao[index].numeros.terceiro =
                data.card[
                  `payment_methods_credit_card_client_number_${card.id}`
                ].split(" ")[2];

              this.contrato.pagamento.credito.cartao[index].numeros.quarto =
                data.card[
                  `payment_methods_credit_card_client_number_${card.id}`
                ].split(" ")[3];
            });

            let updatedParagraphs = this.document_paragraph.map((paragraph) => {
              return {
                id: paragraph.id,
                p: this.renderCreditCardTags(paragraph.p),
                css: paragraph.css,
                page: paragraph.page,
              };
            });
            this.document_paragraph = updatedParagraphs;

            this.getCardDataLoading = false;
            this.showPasswordModal = false;
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.getCardDataLoading = false;
          });
      } else {
        this.$message.error("Insira sua senha.");
      }
    },
    edit(id) {
      this.$router.push(`/documents/edit/${id}`);
    },
    formatPrice(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
    formatDate(date) {
      if (date) {
        console.log("entro");
        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }
        if (date.length > 22) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }
      }
    },
  },
};
</script>

<style>
.document-viewer {
  padding: 50px 40px !important;
  background: #ccc;
  height: 80vh;
  overflow: auto;
  box-shadow: inset 0 0 100px #929292;
  border-radius: 3px;
  border: 1px solid #ddd;
}

.document-viewer h1,
.document-viewer h2,
.document-viewer h3,
.document-viewer h4,
.document-viewer h5,
.document-viewer h6 {
  color: inherit !important;
}

.contract.view {
  background: #fff !important;
  padding: 0;
  margin: 0;
}

@media print {
  body * {
    visibility: hidden;
  }
  @page {
    size: auto;
    margin: 20px 20px;
  }

  #embed-pdf,
  .travel-header.ant-page-header,
  header.ant-layout-header,
  .sidebar {
    display: none !important;
  }

  #the-outer-document,
  #the-outer-document * {
    visibility: visible;
  }

  .sidebar.ant-col.ant-col-6 {
    position: absolute;
  }

  .contract.view {
    background: #fff !important;
  }

  .dashboard .ant-layout-content {
    margin: 0 !important;
    position: absolute;
    left: 0;
    padding: 0 !important;
  }

  .document-viewer {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .ant-row {
    position: absolute !important;
    left: 0 !important;
  }

  .ant-tabs {
    position: absolute;
    left: 0;
    top: 0;
  }

  #the-outer-document {
    position: absolute;
    left: 0;
    top: 0;
  }

  .ant-page-header-ghost {
    position: absolute;
  }

  .contract.view .contract-table {
    box-shadow: none;
  }

  #contrato {
    display: none;
  }

  .contract.view .contract-table .a4 {
    height: 1006px !important;
  }

  .contract.view .contract-table .a4 .td {
    height: 1016px !important;
  }

  .document-viewer {
    padding: 0px !important;
    background: transparent;
    height: 100%;
    overflow: initial;
    box-shadow: none;
    border-radius: 0;
    border: 0;
  }

  @media only screen and (min-device-width: 1601px) and (max-width: 3000px) {
    #pg-print {
      height: auto !important;
    }
  }
}
</style>
